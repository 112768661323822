var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation_uuid
    ? _c(
        "section",
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("MESSAGE_RETENTION"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        staticClass: "w-150",
                        attrs: {
                          trim: "",
                          type: "number",
                          name: "editMessageRetention",
                        },
                        model: {
                          value: _vm.settings.retention,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "retention", $$v)
                          },
                          expression: "settings.retention",
                        },
                      }),
                      _vm._v("\n      " + _vm._s(_vm.$t("DAYS")) + "\n      "),
                      _c("b-form-text", [
                        _vm._v(_vm._s(_vm.$t("0_SAME_ORGANISATION"))),
                      ]),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("EMAILSETTINGS.NOTIFICATIONS.DISABLED_LABEL")
                        ) +
                        "\n    "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            switch: "",
                            value: false,
                            "unchecked-value": true,
                          },
                          model: {
                            value: _vm.settings.new_message_notification,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings,
                                "new_message_notification",
                                $$v
                              )
                            },
                            expression: "settings.new_message_notification",
                          },
                        },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("TO")) + " " + _vm._s(_vm.email)
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("NOTIFICATIONS.TITLE") + " " + _vm.$t("VIEWED")
                        ) +
                        "\n    "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            switch: "",
                            value: true,
                            "unchecked-value": false,
                          },
                          model: {
                            value: _vm.settings.email_notification_opened,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings,
                                "email_notification_opened",
                                $$v
                              )
                            },
                            expression: "settings.email_notification_opened",
                          },
                        },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("TO")) + " " + _vm._s(_vm.email)
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "primary" },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }