var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", { staticClass: "mt-3" }, [
        _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("NAME")))]),
        _c("div", [_vm._v(_vm._s(_vm.information.functionbox.name))]),
        _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
        _c("div", [_vm._v(_vm._s(_vm.information.functionbox.email))]),
        _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("CREATED")))]),
        _c("div", [
          _vm._v(
            _vm._s(_vm.FormatDateTime(_vm.information.functionbox.created_date))
          ),
        ]),
        _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("UUID")))]),
        _c("div", [
          _vm._v(_vm._s(_vm.information.functionbox.functionbox_uuid)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }