<template>
  <section v-if="organisation_uuid">
    <b-overlay :show="loading" rounded="sm" v-if="settings">

      <h5 class="mt-3">{{ $t("MESSAGE_RETENTION") }}</h5>
      <div class="mt-1">
        <b-form-input
          trim
          class="w-150"
          type="number"
          v-model="settings.retention"
          name="editMessageRetention"
        ></b-form-input>
        {{ $t("DAYS") }}
        <b-form-text>{{ $t("0_SAME_ORGANISATION") }}</b-form-text>
      </div>

      <h5 class="mt-3">
        {{ $t("EMAILSETTINGS.NOTIFICATIONS.DISABLED_LABEL") }}
      </h5>
     
      <div class="mt-1">
        <b-form-checkbox
          v-model="settings.new_message_notification"
          switch
          :value="false"
          :unchecked-value="true"
          ><b-form-text
            >{{ $t("TO") }} {{ email }}</b-form-text
          ></b-form-checkbox
        >
      </div>

      <h5 class="mt-3">
        {{ $t("NOTIFICATIONS.TITLE") + " " + $t("VIEWED") }}
      </h5>
     
      <div class="mt-1">
        <b-form-checkbox
          v-model="settings.email_notification_opened"
          switch
          :value="true"
          :unchecked-value="false"
          ><b-form-text
            >{{ $t("TO") }} {{ email }}</b-form-text
          ></b-form-checkbox
        >
      </div>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: {
    email: {
      default: "",
      type: String,
    },
    organisation_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      settings: null,
      loading: false,
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "GeneralMessage",
          organisation_uuid: this.organisation_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      this.settings.retention = parseInt(this.settings.retention);
      this.$http
        .post(this.user.hostname + "/settings/save", self.settings)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>
