<template>
  <section v-if="ExternalFunctionBoxDTO">
    <b-overlay :show="loading" rounded="sm">

      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="changeTab(0)">{{$t('SDK.TITLE')}}</b-nav-item>
        <b-nav-item :active="tab == 1" @click="changeTab(1)">{{$t('FAX.TITLE')}}</b-nav-item>
      </b-nav>
      
      <b-dropdown class="hidden-not-mobile" toggle-class="no-border">
        <template #button-content>
          <span v-if="tab == 0">{{$t('SDK.TITLE') }}</span>
          <span v-if="tab == 1">{{$t('FAX.TITLE') }}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="changeTab(0)">{{$t('SDK.TITLE')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 1" @click="changeTab(1)">{{$t('FAX.TITLE')}}</b-dropdown-item>
      </b-dropdown>


      <div v-if="tab == 0">
        
          <h5 class="mt-3">{{ $t("SDK.IDENTIFIER") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.sdk_identifier"
              name="name"
            ></b-form-input>
          </div>
          
          <h5 class="mt-3">{{ $t("SDK.ENVIRONMENT.TITLE") }}</h5>
          <div class="mt-1">
            <b-form-select
              name="edit_functionbox_sdk_environment"
              v-model="ExternalFunctionBoxDTO.sdk_environment"
            >
              <b-form-select-option value="QA">{{
                $t("SDK.ENVIRONMENT.QA")
              }}</b-form-select-option>
              <b-form-select-option value="PRODUCTION">{{
                $t("SDK.ENVIRONMENT.PRODUCTION")
              }}</b-form-select-option>
              <b-form-select-option value="OPEN_TEST">{{
                $t("SDK.ENVIRONMENT.OPEN_TEST")
              }}</b-form-select-option>
            </b-form-select>
          </div>

          
          <h5 class="mt-3">Webhook</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.sdk_webhook"
              name="sdk_webhook"
            ></b-form-input>
          </div>

      </div>  

      <div v-if="tab == 1">

        <h5 class="mt-3">{{ $t("FAX.NUMBER") }}</h5>
        <div class="mt-1">
          <Phone v-model="ExternalFunctionBoxDTO.fax_number" required></Phone>
        </div>

        <div v-if="showFaxEdit">
          <h5 class="mt-3">{{ $t("FAX.CLIENT_IDENTIFIER") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.fax_client_identifier"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">subscriber_id</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="fax_sip_subscriber_id"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("API") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.fax_api_key"
              name="name"
            ></b-form-input>
          </div>
        </div>

      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" :disabled="saveDisabled" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
import Phone from "@/components/Input/Phone.vue";
export default {
  components: { Phone },
  props: ["information"],
  data() {
    return {
      tab: 1,
      loading: false,
      default: null,
      fax_sip_subscriber_id: this.information.functionbox.fax_sip_subscriber_id + "",
      ExternalFunctionBoxDTO: null,
    };
  },
  methods: {
    changeTab(tab){
      if(this.isChanged)
      {
        let self = this;
        this.$bvModal
          .msgBoxConfirm(this.$t("SAVE_CHANGES"), {
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("YES"),
            cancelTitle: this.$t("NO"),
            bodyClass: "messagebox",
            footerClass: "p-2  text-center messagebox",
            hideHeaderClose: false,
            centered: true,
          })
          .then(function(value) {
            if (value) {              
              self.saveSubmit();
              self.tab = tab;
            }else{              
              self.setExternalFunctionBoxDTO(null);
              self.tab = tab;
            }
          })
          .catch(function() {});        
      }else{
        this.tab = tab;
      }
    },
    setExternalFunctionBoxDTO(item){
      if(this.information.functionbox.sdk_environment == ""){
        this.information.functionbox.sdk_environment = "QA";
      }
      this.ExternalFunctionBoxDTO = {
        functionbox_uuid: item == null ? this.information.functionbox.functionbox_uuid : item.functionbox_uuid,
        sdk_identifier: item == null ? this.information.functionbox.sdk_identifier : item.sdk_identifier,
        sdk_environment: item == null ? this.information.functionbox.sdk_environment : item.sdk_environment,
        sdk_webhook: item == null ? this.information.functionbox.sdk_webhook : item.sdk_webhook,
        fax_number: item == null ? this.information.functionbox.fax_number : item.fax_number,
        fax_client_identifier: item == null ? this.information.functionbox.fax_client_identifier : item.fax_client_identifier,
        fax_api_key: item == null ? this.information.functionbox.fax_api_key : item.fax_api_key,
        fax_sip_subscriber_id: item == null ? 0 : item.fax_sip_subscriber_id,
      };
      this.default = JSON.parse(JSON.stringify(this.ExternalFunctionBoxDTO));
    },
    saveSubmit: function () {
      let self = this;
      this.loading = true;
      this.ExternalFunctionBoxDTO.fax_sip_subscriber_id = parseInt(
        this.fax_sip_subscriber_id
      );
      this.$http
        .post(
          this.user.hostname + "/administration/functionbox/update-external",
          this.ExternalFunctionBoxDTO
        )
        .then(function (result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedUser", result.data);
          self.default = JSON.parse(JSON.stringify(self.ExternalFunctionBoxDTO));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    showFaxEdit() {
      return this.information.functionbox.email.includes("@sefax.se");
    },
    saveDisabled(){
      if(!this.isChanged)
      {
        return true;
      }
      return this.ExternalFunctionBoxDTO.sdk_environment == "";
    },
    isChanged(){
      if(this.default != null)
      {
        let check1 = JSON.parse(JSON.stringify(this.default));
        let check2 = JSON.parse(JSON.stringify(this.ExternalFunctionBoxDTO));
        return JSON.stringify(check1) != JSON.stringify(check2);
      }
      return false;
    }
  },
  mounted: function () {    
    this.setExternalFunctionBoxDTO(null);
  },
};
</script>
<style></style>
