var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            {
              attrs: { title: _vm.$t("ACCESS"), active: "" },
              on: { click: _vm.cancelAdd },
            },
            [
              _c(
                "b-form",
                { staticClass: "mt-2", attrs: { inline: "" } },
                [
                  _c("b-form-input", {
                    staticClass: "searchText newline-mobile ml-0",
                    attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "mt-2",
                attrs: {
                  small: "",
                  busy: _vm.loading,
                  bordered: "",
                  items: _vm.currentItems,
                  hover: "",
                  fields: _vm.fields,
                },
                on: { "row-clicked": _vm.clickedRow },
                scopedSlots: _vm._u([
                  {
                    key: "head(name)",
                    fn: function () {
                      return [
                        _vm.currentItems.length != 0
                          ? _c("b-form-checkbox", {
                              on: { change: _vm.removeAll },
                              model: {
                                value: _vm.remove_all,
                                callback: function ($$v) {
                                  _vm.remove_all = $$v
                                },
                                expression: "remove_all",
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("NAME")) + "\n        "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "head(id)",
                    fn: function () {
                      return [
                        _vm.currentItems.length != 0
                          ? _c("b-form-checkbox", {
                              on: { change: _vm.removeAll },
                              model: {
                                value: _vm.remove_all,
                                callback: function ($$v) {
                                  _vm.remove_all = $$v
                                },
                                expression: "remove_all",
                              },
                            })
                          : _vm._e(),
                        _vm._v("\n           \n        "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(name)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                disabled: _vm.disableCheck(data.item),
                                value: data.item.user_uuid,
                              },
                              model: {
                                value:
                                  _vm.RemoveFunctionBoxAccountDTO.user_uuids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.RemoveFunctionBoxAccountDTO,
                                    "user_uuids",
                                    $$v
                                  )
                                },
                                expression:
                                  "RemoveFunctionBoxAccountDTO.user_uuids",
                              },
                            }),
                            _c("b-img", {
                              staticClass: "profileImage",
                              attrs: {
                                src: "/img/default-avatar.png",
                                alt: "placeholder",
                                rounded: "circle",
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.name) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(email)",
                    fn: function (data) {
                      return [_vm._v(_vm._s(data.item.email))]
                    },
                  },
                  {
                    key: "cell(is_admin)",
                    fn: function (data) {
                      return [_vm._v(_vm._s(_vm.showAdmin(data.item.is_admin)))]
                    },
                  },
                  {
                    key: "cell(new_message_notification)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          _vm._s(
                            data.item.new_message_notification
                              ? _vm.$t("YES")
                              : _vm.$t("NO")
                          )
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(id)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                disabled: _vm.disableCheck(data.item),
                                value: data.item.user_uuid,
                              },
                              model: {
                                value:
                                  _vm.RemoveFunctionBoxAccountDTO.user_uuids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.RemoveFunctionBoxAccountDTO,
                                    "user_uuids",
                                    $$v
                                  )
                                },
                                expression:
                                  "RemoveFunctionBoxAccountDTO.user_uuids",
                              },
                            }),
                            _c("UserInfo", {
                              attrs: {
                                image: "/img/default-avatar.png",
                                primary: data.item.name,
                                secondary: data.item.email,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [_c("b-spinner", { staticClass: "align-middle" })],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-pagination", {
                staticClass: "mt-2",
                attrs: {
                  "total-rows": _vm.totalCurrentItems,
                  "per-page": _vm.limit,
                  align: "center",
                  pills: "",
                },
                on: { input: _vm.getCurrentUsers },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { disabled: !_vm.validRemove, variant: "danger" },
                  on: { click: _vm.removeBtn },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("ADD") }, on: { click: _vm.showAddBtn } },
            [
              _c(
                "b-form",
                { staticClass: "mt-2", attrs: { inline: "" } },
                [
                  _c("b-form-input", {
                    staticClass: "searchText newline-mobile ml-0",
                    attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "mt-2",
                attrs: {
                  small: "",
                  busy: _vm.loading,
                  bordered: "",
                  items: _vm.items,
                  fields: _vm.fields_add,
                },
                on: { "row-clicked": _vm.clickedRow },
                scopedSlots: _vm._u([
                  {
                    key: "head(name)",
                    fn: function () {
                      return [
                        _vm.items.length != 0
                          ? _c("b-form-checkbox", {
                              on: { change: _vm.addAll },
                              model: {
                                value: _vm.add_all,
                                callback: function ($$v) {
                                  _vm.add_all = $$v
                                },
                                expression: "add_all",
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("NAME")) + "\n        "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "head(id)",
                    fn: function () {
                      return [
                        _vm.items.length != 0
                          ? _c("b-form-checkbox", {
                              on: { change: _vm.addAll },
                              model: {
                                value: _vm.add_all,
                                callback: function ($$v) {
                                  _vm.add_all = $$v
                                },
                                expression: "add_all",
                              },
                            })
                          : _vm._e(),
                        _vm._v("\n           \n        "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(name)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c("b-form-checkbox", {
                              attrs: { value: data.item.user_uuid },
                              model: {
                                value: _vm.AddFunctionBoxAccountDTO.user_uuids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.AddFunctionBoxAccountDTO,
                                    "user_uuids",
                                    $$v
                                  )
                                },
                                expression:
                                  "AddFunctionBoxAccountDTO.user_uuids",
                              },
                            }),
                            _c("b-img", {
                              staticClass: "profileImage",
                              attrs: {
                                src: "/img/default-avatar.png",
                                alt: "placeholder",
                                rounded: "circle",
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.name) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(email)",
                    fn: function (data) {
                      return [_vm._v(_vm._s(data.item.email))]
                    },
                  },
                  {
                    key: "cell(id)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c("b-form-checkbox", {
                              attrs: { value: data.item.user_uuid },
                              model: {
                                value: _vm.AddFunctionBoxAccountDTO.user_uuids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.AddFunctionBoxAccountDTO,
                                    "user_uuids",
                                    $$v
                                  )
                                },
                                expression:
                                  "AddFunctionBoxAccountDTO.user_uuids",
                              },
                            }),
                            _c("UserInfo", {
                              attrs: {
                                image: "/img/default-avatar.png",
                                primary: data.item.name,
                                secondary: data.item.email,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [_c("b-spinner", { staticClass: "align-middle" })],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-pagination", {
                staticClass: "mt-2",
                attrs: {
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.limit,
                  align: "center",
                  pills: "",
                },
                on: { input: _vm.getUsers },
                model: {
                  value: _vm.usersPage,
                  callback: function ($$v) {
                    _vm.usersPage = $$v
                  },
                  expression: "usersPage",
                },
              }),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { disabled: !_vm.valid, variant: "primary" },
                  on: { click: _vm.addBtn },
                },
                [_vm._v(_vm._s(_vm.$t("ADD")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "add-functionbox-users-settings",
            "hide-header": "",
            "dialog-class": _vm.teams.theme,
            centered: "",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("ADD"),
          },
          on: { cancel: _vm.cancelSave, ok: _vm.okSave },
          model: {
            value: _vm.functionBoxUserSettings,
            callback: function ($$v) {
              _vm.functionBoxUserSettings = $$v
            },
            expression: "functionBoxUserSettings",
          },
        },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "mt-2",
              attrs: {
                id: "checkbox-is_admin",
                name: "checkbox-is_admin",
                value: 1,
                switch: "",
                "unchecked-value": 0,
              },
              model: {
                value: _vm.AddFunctionBoxAccountDTO.is_admin,
                callback: function ($$v) {
                  _vm.$set(_vm.AddFunctionBoxAccountDTO, "is_admin", $$v)
                },
                expression: "AddFunctionBoxAccountDTO.is_admin",
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("FUNCTIONBOX_ADMIN")) + "\n    "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "mt-2",
              attrs: {
                id: "checkbox-new_message_notification",
                name: "checkbox-new_message_notification",
                value: true,
                switch: "",
                "unchecked-value": false,
              },
              model: {
                value: _vm.AddFunctionBoxAccountDTO.new_message_notification,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.AddFunctionBoxAccountDTO,
                    "new_message_notification",
                    $$v
                  )
                },
                expression: "AddFunctionBoxAccountDTO.new_message_notification",
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("FUNCTIONBOX_NOTIFICATIONS")) + "\n  "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }