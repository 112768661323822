<template>
  <section class="mt-3">
    <b-overlay :show="loading" rounded="sm">

      <div v-if="tabIndex == 0">
        
        <h5 class="mt-3">{{ $t("NAME") }}</h5>
        <div class="mt-1">
          <b-form-input
            trim
            required
            type="text"
            v-model="UpdateFunctionBoxDTO.name"
            name="name"
          ></b-form-input>
        </div>

        <h5 class="mt-3">{{ $t("LANGUAGE.TITLE") }}</h5>
        <div class="mt-1">
          <b-form-select
            name="edit_functionbox_language"
            v-model="UpdateFunctionBoxDTO.locale"
          >
            <b-form-select-option
              v-for="(value, key) in sefos_locales"
              :key="key"
              :value="key"
            >
              {{ $t("LANGUAGE." + key) }}
            </b-form-select-option>
          </b-form-select>
        </div>

        <h5 class="mt-3">{{ $t("SIGNATURE") }}</h5>
        <div class="mt-1">

          <b-form-textarea
            v-model="UpdateFunctionBoxDTO.signature"
            rows="5"
            max-rows="6"
          ></b-form-textarea>
        </div>

      </div>

      <div v-if="tabIndex == 1">
        
        <h5 class="mt-3">{{ $t("MAIL_SERVER") }}</h5>
        <div class="mt-1">

          <b-form-select
            v-model="UpdateFunctionBoxDTO.email_provider"
            :options="providerOptions"
          ></b-form-select>
        </div>

        <h5 class="mt-3">{{ $t("EMAILSETTINGS.FROM") }}</h5>
        <div class="mt-1">
          <b-form-radio
            v-if="UpdateFunctionBoxDTO.email_provider == 'ORGANISATION'"
            v-model="UpdateFunctionBoxDTO.email_force_from_email"
            value="0"
            >{{ information.organisation_email_from }}</b-form-radio
          >
          <b-form-radio
            class="mt-2"
            v-if="UpdateFunctionBoxDTO.email_provider == 'ORGANISATION'"
            v-model="UpdateFunctionBoxDTO.email_force_from_email"
            value="1"
            >{{ information.functionbox.email }}</b-form-radio
          >

          <b-form-input
            trim
            v-if="UpdateFunctionBoxDTO.email_provider != 'ORGANISATION'"
            plaintext
            required
            type="text"
            v-model="information.functionbox.email"
            name="email"
          ></b-form-input>
        </div>

      </div>

      <div
        v-if="
          UpdateFunctionBoxDTO.email_provider != 'SEFOS' &&
          UpdateFunctionBoxDTO.email_provider != 'ORGANISATION'
        "
      >
        <h5 class="mt-3">
          {{ $t("EMAILSETTINGS.PROTOCOL_TITLE") }}
        </h5>
        <div class="mt-1">
          <b-form-select
            name="edit_functionbox_email_mode"
            v-model="UpdateFunctionBoxDTO.email_mode"
            :options="email_mode"
          >
          </b-form-select>
        </div>

        <div v-show="UpdateFunctionBoxDTO.email_mode == 'SMTP'">
          <h5 class="mt-3">{{ $t("EMAILSETTINGS.HOSTNAME") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="UpdateFunctionBoxDTO.email_hostname"
              name="email"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.PORT") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="number"
              v-model="UpdateFunctionBoxDTO.email_port"
              name="email"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.USERNAME") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="UpdateFunctionBoxDTO.email_username"
              name="email"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.PASSWORD") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="password"
              v-model="UpdateFunctionBoxDTO.email_password"
              name="email"
            ></b-form-input>
          </div>

          <h5 class="mt-3">
            {{ $t("EMAILSETTINGS.SECURITY.TITLE") }}
          </h5>
          <div class="mt-1">
            <b-form-select
              v-model="UpdateFunctionBoxDTO.email_security"
              :options="email_securitys"
            >
            </b-form-select>
          </div>
        </div>


        <div v-show="UpdateFunctionBoxDTO.email_mode == 'GRAPH'">
          <h5 class="mt-3">{{ $t("EMAILSETTINGS.TENANT") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              type="text"
              v-model="UpdateFunctionBoxDTO.email_graph_tenant"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.CLIENTID") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              type="text"
              v-model="UpdateFunctionBoxDTO.email_graph_client_id"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">
            {{ $t("EMAILSETTINGS.CLIENTSECRET") }}
          </h5>
          <div class="mt-1">
            <b-form-input
              trim
              type="password"
              v-model="UpdateFunctionBoxDTO.email_graph_client_secret"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.REPLY_TO") }}</h5>
          <div class="mt-1">
            <b-form-input
              trim
              required
              type="text"
              v-model="UpdateFunctionBoxDTO.email_replyto"
              name="email"
            ></b-form-input>
          </div>
        </div>

      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>

      <b-button
        variant="secondary"
        :disabled="testDisabled"
        v-if="tabIndex == 1"
        class="btn-fill"
        @click="testBtn"
        >{{ $t("TEST") }}</b-button
      >

      <b-button
        variant="danger"
        class="btn-fill float-right"
        v-if="tabIndex == 0"
        @click="showRemoveFunctionbox = true"
        >{{ $t("REMOVE") }}</b-button
      >

      <b-modal
        v-model="showRemoveFunctionbox"
        hide-header
        :dialog-class="teams.theme"
        :ok-disabled="okRemoveDisabled"
        @cancel="cancelRemoveFunctionbox"
        @ok="removeSubmit"
        centered
        ok-variant="danger"
        :cancel-title="$t('CANCEL')"
        :ok-title="$t('REMOVE')"
      >
        <div v-html="$t('CONFIRM.DO_YOU_WANT_TO_REMOVE_FUNCTIONBOX')"></div>

        <hr class="p-0 m-0 mt-4 mb-2" />

        <b-form-group
          id="input-1"
          :label="$t('REMOVE_FUNCTIONBOX_NAME')"
          label-for="input-2"
        >
          <b-alert show variant="info">{{
            information.functionbox.name
          }}</b-alert>
          <b-form-input
            trim
            v-model="remove_functionbox_name"
            aria-describedby="label-help-block"
            placeholder=""
          ></b-form-input>
        </b-form-group>
      </b-modal>

      
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["information", "tabIndex"],
  data() {
    return {
      loading: false,
      showRemoveFunctionbox: false,
      remove_functionbox_name: "",
      email_mode: [
        { value: "SMTP", text: this.$t("EMAILSETTINGS.SMTP") },
        { value: "GRAPH", text: this.$t("EMAILSETTINGS.MSGRAPH") },
      ],
      providerOptions: [
        { value: "ORGANISATION", text: this.$t("ORGANISATION.TITLE") },
        { value: "CUSTOM", text: this.$t("CUSTOM") },
      ],
      email_securitys: [
        { value: 0, text: this.$t("EMAILSETTINGS.SECURITY.0") },
        { value: 2, text: this.$t("EMAILSETTINGS.SECURITY.2") },
        { value: 3, text: this.$t("EMAILSETTINGS.SECURITY.3") },
      ],
      UpdateFunctionBoxDTO: {
        functionbox_uuid: this.information.functionbox.functionbox_uuid,
        name: this.information.functionbox.name,
        email_provider: this.information.functionbox.email_provider,
        email_hostname: this.information.functionbox.email_hostname,
        email_username: this.information.functionbox.email_username,
        email_password: this.information.functionbox.email_password,
        email_replyto: this.information.functionbox.email_replyto,
        email_force_from_email:
          this.information.functionbox.email_force_from_email,
        email_security: this.information.functionbox.email_security,
        email_port: this.information.functionbox.email_port,
        locale: this.information.functionbox.locale,
        email_mode: this.information.functionbox.email_mode,
        email_graph_tenant: this.information.functionbox.email_graph_tenant,
        email_graph_client_id:
          this.information.functionbox.email_graph_client_id,
        email_graph_client_secret:
          this.information.functionbox.email_graph_client_secret,
        signature: this.information.functionbox.signature,
        email_notification_disabled:
          this.information.functionbox.email_notification_disabled,
      },
      RemoveFunctionBoxDTO: {
        functionbox_uuid: this.information.functionbox.functionbox_uuid,
      },
    };
  },
  methods: {
    cancelRemoveFunctionbox() {
      this.showRemoveFunctionbox = false;
      this.remove_functionbox_name = "";
    },
    testBtn: function () {
      let self = this;
      self.loading = true;
      self.$http
        .get(
          self.user.hostname +
            "/administration/functionbox/email/test/" +
            self.information.functionbox.functionbox_uuid
        )
        .then(function () {
          self.$noty.info(self.$t("SENT"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    saveSubmit: function () {
      let self = this;
      this.loading = true;
      this.UpdateFunctionBoxDTO.email_port = parseInt(
        this.UpdateFunctionBoxDTO.email_port
      );
      this.$http
        .post(
          this.user.hostname + "/administration/functionbox/update",
          this.UpdateFunctionBoxDTO
        )
        .then(function (result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedInfo", result.data);
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    async removeSubmit() {
      try {
        this.loading = true;
        await this.$http.post(
          this.user.hostname + "/administration/functionbox/remove",
          this.RemoveFunctionBoxDTO
        );
        this.$emit("removedUser");
        this.$noty.info(this.$t("REMOVED"));
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
  computed: {
    okRemoveDisabled() {
      return this.information.functionbox.name != this.remove_functionbox_name;
    },
    testDisabled() {
      return (
        this.information.functionbox.email_provider !=
        this.UpdateFunctionBoxDTO.email_provider
      );
    },
  },
  mounted: function () {},
};
</script>
<style></style>
