var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ExternalFunctionBoxDTO
    ? _c(
        "section",
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-nav",
                { staticClass: "hidden-mobile", attrs: { tabs: "" } },
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 0 },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("SDK.TITLE")))]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FAX.TITLE")))]
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown",
                {
                  staticClass: "hidden-not-mobile",
                  attrs: { "toggle-class": "no-border" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _vm.tab == 0
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("SDK.TITLE"))),
                                ])
                              : _vm._e(),
                            _vm.tab == 1
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("FAX.TITLE"))),
                                ])
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3785715017
                  ),
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 0 },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("SDK.TITLE")))]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FAX.TITLE")))]
                  ),
                ],
                1
              ),
              _vm.tab == 0
                ? _c("div", [
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v(_vm._s(_vm.$t("SDK.IDENTIFIER"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "name",
                          },
                          model: {
                            value: _vm.ExternalFunctionBoxDTO.sdk_identifier,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ExternalFunctionBoxDTO,
                                "sdk_identifier",
                                $$v
                              )
                            },
                            expression: "ExternalFunctionBoxDTO.sdk_identifier",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v(_vm._s(_vm.$t("SDK.ENVIRONMENT.TITLE"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "b-form-select",
                          {
                            attrs: { name: "edit_functionbox_sdk_environment" },
                            model: {
                              value: _vm.ExternalFunctionBoxDTO.sdk_environment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ExternalFunctionBoxDTO,
                                  "sdk_environment",
                                  $$v
                                )
                              },
                              expression:
                                "ExternalFunctionBoxDTO.sdk_environment",
                            },
                          },
                          [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "QA" } },
                              [_vm._v(_vm._s(_vm.$t("SDK.ENVIRONMENT.QA")))]
                            ),
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "PRODUCTION" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("SDK.ENVIRONMENT.PRODUCTION"))
                                ),
                              ]
                            ),
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "OPEN_TEST" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("SDK.ENVIRONMENT.OPEN_TEST"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("h5", { staticClass: "mt-3" }, [_vm._v("Webhook")]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "sdk_webhook",
                          },
                          model: {
                            value: _vm.ExternalFunctionBoxDTO.sdk_webhook,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ExternalFunctionBoxDTO,
                                "sdk_webhook",
                                $$v
                              )
                            },
                            expression: "ExternalFunctionBoxDTO.sdk_webhook",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.tab == 1
                ? _c("div", [
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v(_vm._s(_vm.$t("FAX.NUMBER"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c("Phone", {
                          attrs: { required: "" },
                          model: {
                            value: _vm.ExternalFunctionBoxDTO.fax_number,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ExternalFunctionBoxDTO,
                                "fax_number",
                                $$v
                              )
                            },
                            expression: "ExternalFunctionBoxDTO.fax_number",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.showFaxEdit
                      ? _c("div", [
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v(_vm._s(_vm.$t("FAX.CLIENT_IDENTIFIER"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "name",
                                },
                                model: {
                                  value:
                                    _vm.ExternalFunctionBoxDTO
                                      .fax_client_identifier,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ExternalFunctionBoxDTO,
                                      "fax_client_identifier",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ExternalFunctionBoxDTO.fax_client_identifier",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v("subscriber_id"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "name",
                                },
                                model: {
                                  value: _vm.fax_sip_subscriber_id,
                                  callback: function ($$v) {
                                    _vm.fax_sip_subscriber_id = $$v
                                  },
                                  expression: "fax_sip_subscriber_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v(_vm._s(_vm.$t("API"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "name",
                                },
                                model: {
                                  value: _vm.ExternalFunctionBoxDTO.fax_api_key,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ExternalFunctionBoxDTO,
                                      "fax_api_key",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ExternalFunctionBoxDTO.fax_api_key",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { variant: "primary", disabled: _vm.saveDisabled },
                  on: { click: _vm.saveSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }