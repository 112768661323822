<template>
  <section class="mt-3" v-if="information">

      <h5 class="mt-2">{{ $t("NAME") }}</h5>
      <div>{{ information.functionbox.name }}</div>
      
      <h5 class="mt-2">{{ $t("EMAIL") }}</h5>
      <div>{{ information.functionbox.email }}</div>
      
      <h5 class="mt-2">{{ $t("CREATED") }}</h5>
      <div>{{ FormatDateTime(information.functionbox.created_date) }}</div>
      
      <h5 class="mt-2">{{ $t("UUID") }}</h5>
      <div>{{ information.functionbox.functionbox_uuid }}</div>

  </section>
</template>
<script>
export default {
  props: ["information"],
  data() {
    return {   
    };
  },
  methods: {    
  },
  mounted: function() {
  }
};
</script>
<style>
</style>